* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact_section {
  position: relative;
  background-image: url('Assets/contactBg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact_section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  z-index: 1;
}

.contact_section * {
  position: relative;
  z-index: 2;
}
